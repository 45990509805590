import * as React from "react"
import PropTypes from "prop-types"
import "./layout.scss"
import Header from "./header"
import { Footer } from "./footer"
import { VisualEditing } from "./visualEditing"

export const Layout = (props) => {

  const {children} = props
  

  return (
    <>
     <VisualEditing {...props}/>
      <Header/>
      <main>
        {children}
      </main>
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

