import React from "react"
import { Link as GatsbyLink } from "gatsby-theme-material-ui"
import {Link} from "@mui/material"

export const CheckLink = (props) => {

  const {children, to, activeClassName, partiallyActive, other} = props
  const internal = /^\/(?!\/)/.test(to)

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...props}
      >
        {children}
      </GatsbyLink>
    )
  }
  return (
    <Link href={to} {...props}>
      {children}
    </Link>
  )
}

export const prefixLink = (node) => {

  // Primarily used with inline text. Could be extended for all links?

  let definedLink = ""
  if(node?._type === "file" ) {
    definedLink = node?.asset?.url
  }
  if(node?._type === "externalLink") {
    definedLink = node?.link?.external
  }
  if(node?._type === "link") {
    definedLink = node?.href
  }
  if(node?._type === "internalLink") {
    if(node?.reference?._type === "post"){
      definedLink = `/blog/${node?.reference?.slug?.current}`
    }
    if(node?.reference?._type === "page"){
      definedLink = `/${node?.link?.internal?.slug.current}`
    }
  }
  if(node?._type === "page"){
    definedLink = `/${node?.internal?.slug?.current}`
  }
  if(node?._type === "navigationItem"){
    if(node?.link?.link?.internal?._type === "page"){
      definedLink = `/${node?.link?.link?.internal?.slug.current}`
    }
    if(node.link.link.internal._type === "post"){
      definedLink = `/blog/${node?.link?.link?.internal?.slug?.current}`
    }
  }
  return definedLink
}

  