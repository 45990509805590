import React from "react"
import { Container, useTheme, Button, Divider } from "@mui/material"
import LoginIcon from '@mui/icons-material/Login';
import { NavigationItem } from "./navigationItem"


const MainNavigation = (props) => {
  const theme = useTheme()
  const { handleCloseNavMenu, menu } = props

  return (
    <Container sx={{ position: "relative", pt: { xs: 1, sm: 0, md: 0 }, pb: { xs: 11, sm: 0, md: 0 }, marginTop: { xs: 0, sm: 0, md: 0 }, flexBasis: { sm: '100%', sm: 'auto' }, flexGrow: 1, order: { sm: 0, md: 0, lg: 0 }, display: { sm: 'flex' }, flexDirection: { xs: 'column', sm: 'row', md: 'row', lg: 'row' }, alignItems: {xs: "flex-start", sm: "flex-start", md: "flex-start", lg:"flex-start"}, justifyContent: { xs: 'flex-start', sm: 'flex-end', md: 'flex-end' } }}>
      {/* <Divider variant="middle" color="text.main" sx={{ position: "absolute", top: 8, left: 0, right: 0, mb: theme.spacing(8) }} /> */}
      {menu?.sanityNavigation?.items && menu.sanityNavigation.items.map((menuItem, i) => {

        return (
          <NavigationItem
            handleCloseNavMenu={handleCloseNavMenu}
            key={`main-menu-item-${i}`}
            link={menuItem?.link?.link}
            childNode={menuItem?.childItems}
            text={menuItem?.link?.text}
            to={menuItem?.link}
          />
        )
      })
      }
  
    </Container>
  )
}

export default MainNavigation