import React, { useState } from "react"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
// import { animationHover } from "../utils/animationHover"
import { Link, Box, Menu, MenuItem, useTheme, Button, useMediaQuery, Accordion, AccordionSummary, AccordionDetails } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const NavigationItem = (props) => {

  const { childNode, text, key, to, handleCloseNavMenu } = props

  //Desktop Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = anchorEl;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleHover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // tablet Menu
  const [expanded, setExpanded] = useState('panel1');

  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };


  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const lg = useMediaQuery(theme.breakpoints.down("lg"))



  return (
    <Box
      sx={{ my: 0, mx: { xs: 0, md: 0 }, px: { xs: 0, md: 0 }, color: "highlight.main", display: 'flex', textTransform: "unset" }}
    >
      {childNode && childNode.length >= 1 &&
        <Box sx={{display: {xs: "none", sm: "block"}}}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
            //onMouseLeave={handleHoverOut}
            sx={{
              color: theme.palette.text.primary,
              textAlign: { xs: 'left', sm: 'center' },
              justifyContent: { xs: 'left', sm: 'center' },
              fontWeight: 700,
            }}
            endIcon={childNode.length >= 1 && <ExpandMoreIcon />}
            size="large"
          >{text}</Button>


          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            slotProps={{
              paper: {
                elevation: 0
              }
            }}
            sx={{ mt: theme.spacing(2) }}
          >
            {childNode && childNode.map((node, i) => {

              return (
                <MenuItem key={`submenu-${i}-${node?.text}`} onClick={handleClose} disableRipple sx={{
                  px: theme.spacing(2),
                  "&:hover":{
                    backgroundColor: "transparent",
                  }
                }}>
                  {node?.link?.internal ? (
                    <GatsbyButton variant="text" color='secondary' to={`/${node?.link?.internal?.slug?.current}`} sx={{
                      px: theme.spacing(1),
                      justifyContent: 'left',
                      fontWeight: 700,
                      "&:hover":{
                        backgroundColor: "transparent",
                        color: "primary.main"
                      }
                    }}
                      size="large"
                    >{node?.text}</GatsbyButton>
                  ) : node?.link?.external ? (
                    <Link size="large" color='secondary' href={node?.link?.external} sx={{
                      px: theme.spacing(1),
                      justifyContent: 'left',
                      fontWeight: 700,
                      "&:hover":{
                        backgroundColor: "transparent",
                        color: "primary.main"
                      }
                    }}>{node?.text}</Link>
                  ) : null}
                </MenuItem>
              )
            })}
          </Menu>
        </Box>}

      {childNode && childNode.length === 0 &&
        <Box sx={{display: {xs: "none", md: "block"}}}>
          <GatsbyButton
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            //onClick={handleClick}
            // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
            //onMouseLeave={handleHoverOut}
            sx={{
              color: theme.palette.text.primary,
              borderBottom: `1px solid ${open ? theme.palette.text.primary : `transparent`}`,
              textAlign: { xs: 'left', sm: 'center' },
              justifyContent: { xs: 'left', sm: 'center' },
              fontWeight: 700,
            }}
            endIcon={childNode.length >= 1 && <ExpandMoreIcon />}
            size="large"
            to={to?.link?.internal ? `/${to?.link?.internal?.slug?.current}` : to?.link?.external}
          >{text}</GatsbyButton>


          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              backgroundColor: theme.palette.background.main,
            }}
          >
            {childNode && childNode.map((node, i) => {

              return (
                <MenuItem key={`submenu-${i}-${node?.text}`} onClick={handleClose} disableRipple>
                  {node?.link?.internal ? (
                    <GatsbyButton sx={{ fontWeight: 700,}} size="large" color="tertiary" variant="text" to={`/${node?.link?.internal?.slug?.current}`}>{node?.text}</GatsbyButton>
                  ) : node?.link?.external ? (
                    <Link sx={{ fontWeight: 700,}}size="large" href={node?.link?.external}>{node?.text}</Link>
                  ) : null}
                </MenuItem>
              )
            })}
          </Menu>
        </Box>}


      {// sm Menus
      }
      {
        childNode && childNode.length === 0 && 

        <GatsbyButton 
          size="large" 
          color='tertiary'
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="text"
          disableElevation
          onClick={handleCloseNavMenu}
          to={`/${to?.link?.internal?.slug?.current}`}
          // onMouseEnter={handleHover} // Disabled for the moment to get it all styled
          //onMouseLeave={handleHoverOut}
          sx={{
            display:{xs: "block", md: "none"},
            color: theme.palette.text.primary,
            //borderBottom: `1px solid ${open ? theme.palette.text.primary : `transparent`}`,
            textAlign: { xs: 'left', md: 'center' },
            justifyContent: { xs: 'left', md: 'center' },
            px: theme.spacing(1),
            fontWeight: 700,
          }}
          endIcon={childNode.length >= 1 && <ExpandMoreIcon />}
        >{text}</GatsbyButton>
      }



      {childNode && childNode.length >= 1 &&

        <Accordion disableGutters elevation={0} expanded={expanded === `${key}`} onChange={handleChange(`${key}`)} sx={{
          // backgroundColor: theme.palette.background.main,
          pb: 0,
          px: theme.spacing(0),
          display: {xs: "block", sm: "none"},
          '& .MuiAccordionSummary-content': {
            my: theme.spacing(1),
            mb: 0,
    
          }
        }}>
          <AccordionSummary aria-controls={`${key}d-content`} id={`${key}d-content`} sx={{
            px: theme.spacing(0),
            // backgroundColor: theme.palette.background.main,
            border: "none !important",
          }}>

            <Button
              id="demo-customized-button"
              variant="text"
              disableElevation
              sx={{
                color: theme.palette.text.primary,
                //borderBottom: `1px solid ${expanded === `${key}` ? theme.palette.text.primary : `transparent`}`,
                textAlign: { xs: 'left', md: 'center' },
                justifyContent: { xs: 'left', md: 'center' },
                fontWeight: expanded === `${key}` ? '500' : '400',
                px: theme.spacing(1),
                border: "none !important",
              }}
              endIcon={childNode.length >= 1 && expanded === `${key}` ? <ExpandMoreIcon /> : <ExpandMoreIcon sx={{ transform: 'rotate(-90deg)' }} />}
              size="large" >{text}</Button>

          </AccordionSummary>
          {childNode && childNode.map((node, i) => {

            return (

              <AccordionDetails sx={{
                py: theme.spacing(0),
                px: theme.spacing(0),
              }}>

                {node.link.internal ? (
                  <GatsbyButton onClick={handleCloseNavMenu} size="large" variant="text" color='tertiary' to={`/${node.link.internal?.slug?.current}`}
                    sx={{
                      justifyContent: "flex-start",
                      color: theme.palette.text.primary,
                      py: theme.spacing(1),
                      fontWeight: 700,
                      px: theme.spacing(2),
                    }}>{node.text}</GatsbyButton>
                ) : node.link.external ? (
                  <Link onClick={handleCloseNavMenu} xs={{
                    fontWeight: 700,
                    px: theme.spacing(2),
                  }} href={node.link.external}>{node.text}</Link>
                ) : null}

              </AccordionDetails>

            )
          })}
        </Accordion>
      }



    </Box>
  )
}